
   .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 80vh;
    background-color: #F5F5DC;
    overflow: auto;
    /* overflow:scroll; */
  }
  
  .modal-content {
    background-color: #FFFFFF;
    padding: 20px;
    border: 1px solid #5D4037;
    border-radius: 5px;
    max-width: 30%;
    max-height: 80%;
    /* overflow: auto; */
    position: relative;
    text-align: center;
    transition: filter 0.3s ease;
  }
  
  .modal-content.blurred {
    filter: blur(5px);
  }
  
  .confirmation-icon-container {
    background-color: hsl(90, 48%, 51%);
    /* background-color: #FFE082; */
    background-color: #C8E6C9;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  
  .confirmation-icon {
    color: #FFB300;
    color: #388E3C; 
    font-size: 48px;
  }
  
  h2 {
    margin-bottom: 10px;
    color: #388E3C; 
    padding-top: 5%;
  }
  
  #para{
    margin-bottom: 10px;
    /* color:#FFC107; */
    color:black;
  }
  #headColor{
    color: black;
  }
  
  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  @media (max-width: 770px) {
    .modal {
      /* border: 2px solid red; */
      padding: 0%;
    }
    .modal-content {
      border: 2px solid rgb(0, 13, 255);

      max-width: 90%;
      border: none;
    }
    .modal {
     
      background-color: white;
    
      
    }
    .confirmation-icon-container{
      background-color: white;
    } 
  }