/* src/components/Navbar.css */
.navbar {
    background: #FFFFFF;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    border-bottom: 1px solid rgb(246, 244, 244);
    /* z-index: 2000000000; */

}

.navbar-container {
    display: flex;
    /* justify-content: flex-start;  */
    align-items: center;
    width: 100%;
    /* border: 1px solid red; */
    max-width: 1500px;
    padding: 0 24px;
}

.navbar-left {
    display: flex;
    align-items: center;
    width: 100%;
}

.navbar-logo  {
    height: auto;
    /* padding-left: %; */
    max-width: 100%;
}

.navbar-logo img {
    width: 100px;
    height: 70px;
    /* width: 13%;
    height: 13%; */
    width: 110px;
    aspect-ratio: auto 110 / 65.6104;
}


.menu-icon {
    display: none;
    /* margin-left: auto;  */
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    /* margin-left: 20px;  */
    z-index: 123;
}

.nav-item {
    /* height: 100px; */
    width: 100%;
    /* background-color: ; */
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.nav-links {
    color: #817f7f;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1rem 1rem;
    /* padding: none; */
    justify-content: center;
    height: 100%; 
    cursor: pointer;
    text-align: center;
}

.nav-links:hover {
    color: #f0a500;
    /* border: 1px solid red; */
    transition: all 0.3s ease;
}

@media screen and (max-width: 960px) {
    .menu-icon {
        display: block;
        /* font-size: rem; */
        /* font-weight:lighter; */
        /* font-weight: 20; */
        cursor: pointer;
        /* color: #fff; */
        color: black;
        color: rgb(60, 100, 62);
        
        height: 2rem;
    width: 2rem;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        background: #333;
        background-color: #F7F9F7;

    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        height: 60px;
        /* border: 2px solid red; */
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
    border: 1px solid rgb(233, 238, 235);

        display: flex;
        justify-content: center;
/* border-bottom: 2px solid red; */
        background-color: #F7F9F7;

    }
.navbar-logo  {
/* padding-left:4%; */
/* padding: 2%; */
/* border: 1px solid red; */
}
    .nav-links:hover {
        color: #f0a500;
        transition: all 0.3s ease;
    }
}
