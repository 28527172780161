

  .formPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;

    background-color: #F5F5DC;
  color: #8B5E3C;

  }
  /* h2 {
    color: #D2691E;
  } */
  #headColor{
    color: black;
  }

  .formPage-content {
    background-color: #fff;
    padding: 20px;
    /* border-radius: 8px; */
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .formPage-content form {
    display: flex;
    flex-direction: column;
  }
  
  .formPage-content h2 {
    margin-bottom: 10px;
    color: #FFC324;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;

    /* display: block;
  margin: 15px 0 5px; */
  color: #64A051;
  color: #8B5E3C;
  }
  
  .form-group input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    border: 1px solid #64A051; 
    border: 1px solid #DDC3A5;

  }
  input:focus {
    border-color: #FF7F11; 
    outline: none;
  }
  .formPage-content button {
    padding: 15px 20px;
    margin-top: 10px;
    background-color: #FFC324; 
    color: #FFFFFF;    
    border: none;
    border-radius: 4px;
    font-weight: 560;
    font-size: larger;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .formPage-content button:hover {
    background-color: #b29d18;
  }
  .formPage.blur .formPage-content {
    filter: blur(5px); 
  }

  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
.logoContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo{
  width: 45%;
  height: 35%;
  /* background-color: #b29d18; */
}

  /* @media (max-width: 600px) { */
  @media (max-width: 770px) {
    .formPage-content {
      /* width: 90%; */
      width: 100%;
      height: 100vh;
      padding: 15px;
      box-shadow: none;
    }
    .formPage-content h2{
      /* margin: 30px ; */
      /* margin-bottom: 200px; */
      padding-bottom: 20px;
      padding-top: 20px;
      color: #FFC107;

      /* border: 2px solid red; */
    }
    .form-group {
      margin-bottom: 8px;
    }
    
    .form-group label {
      margin-bottom: 4px;
    }
    
    .form-group input {
      padding: 6px;
    }
    
    .formPage-content button {
      padding: 8px 16px;
      margin-top: 8px;
    }
  }
  