/* src/Components/Footer.css */
.footer {
    background: #fff;
    color: #6f6e6e;
    /* background: #333;
    color: #fff; */
    padding: 30px 0;
    border-top: 1px solid rgb(243, 238, 238);
    font-family: Assistant, sans-serif;
    font-size: 14px;
  }
  
  .footer-container {
    display: flex;
    /* justify-content: space-between; */
    /* justify-content:flex-start; */
    /* border: 1px solid red; */
    /* justify-content: end; */
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 65px;
    /* padding: 0 20px; */
    flex-wrap: wrap;
  }
  
  .footer-left {
    flex: 1;
    display: flex;
    align-items: center;
    text-align: center;
    /* text-align: left; */
    /* border: 1px solid rgb(55, 255, 0); */
    width: 90%;
    /* color: rgba(150, 149, 136, 1); */
    /* color: black; */
  }
  
  .footer-right {
    display: flex;
    gap: 30px;
    /* border: 2px solid red; */
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  
  .footer-link {
    color: #fff;
    color: #6f6e6e;

    text-decoration: none;
  }
  
  .footer-link:hover {
    color: #F4933E;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: center;
      gap: 35px;
    }
  
    .footer-right {
      margin-top: 10px;
      gap: 10px;
    }
  }
  